
let devToken = null
if (process.env.NODE_ENV === 'development') {
    devToken = require('./dev').devToken
}

const KOKIA_API = 'https://nt4w0ln1wi.execute-api.ap-northeast-1.amazonaws.com'

export const loginWithCode = (code, redirect_uri) => {
    if (process.env.NODE_ENV === 'development')
        return loginWithToken(devToken)

    return fetch(`${KOKIA_API}/user/login`, {
            method: "POST",
            mode: "cors",
            body: JSON.stringify({
                code: code,
                redirect_uri: redirect_uri
            })
        })
        .then((response) => response.json())
        .then((userInfo) => {
            if (userInfo.error) {
                throw new Error(userInfo.error)
            }
            return userInfo
        })
}


export const loginWithToken = (token) => fetch(`${KOKIA_API}/user/login`, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify({token})
})
.then((response) => response.json())
.then((userInfo) => {
    if (userInfo.error) {
        throw new Error(userInfo.error)
    }
    return userInfo
})

export const getUserList = (token, last_key) => fetch(`${KOKIA_API}/user/list`, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify({token, last_key})
})
.then((response) => response.json())
.then((users) => {
    if (users.error) {
        throw new Error(users.error)
    }
    return users
})

export const updateGrant = (token, line_id, change, grant) => fetch(`${KOKIA_API}/user/update`, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify({
        token,
        line_id,
        update: {grants: {change, arg: grant}}
    })
})
.then((response) => response.json())
.then((updateResult) => {
    if (updateResult.error) {
        throw new Error(updateResult.error)
    }
    return updateResult
})