import "./login.css"
import { Navigate, useSearchParams } from "react-router-dom";
import { useLogin } from "./login-provider";
import { useEffect, useState } from "react";
import { loginWithCode } from "../api/userApi"

let loginPromise = null
const doLogin = (code, state) => new Promise((resolve, reject) => {
    const initialState = localStorage.getItem('lineLoginState')
    localStorage.removeItem('lineLoginState')

    if (initialState !== state) {
        reject({message: 'Line redirect state mismatch'})
    } else {
        resolve(loginWithCode(code, window.location.origin + '/login'))
    }
})

export const LoginLanding = () => {
    const {onLogin} = useLogin()
    const [searchParams] = useSearchParams();
    const [loginProgress, setLoginProgress] = useState('waiting')

    const code = searchParams.get('code')
    const state = searchParams.get('state')

    useEffect(() => { // perform UI side login
        let abort = false
        if (loginPromise === null) {
            loginPromise = doLogin(code, state)
        }
        loginPromise.then((user) => {
            if (abort) return

            onLogin(user)
            setLoginProgress('success')
        }).catch((error) => {
            if (abort) return

            console.warn(`Login failed. error: ${error.message}`)
            setLoginProgress('error')
        })
    
        return () => { abort = true }
    }, [])  // eslint-disable-line
    
    if (loginProgress !== 'waiting') {
        return <Navigate to="/" replace />
    }

    return (
        <div className="login-container">
            少々お待ちください...
            <div className="progress-bar">
            <div className="progress-bar-value"></div></div>
        </div>
    )
}
