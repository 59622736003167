import './user.css';
import {
    getUserList as userApi_getUserList,
    updateGrant as userApi_updateGrant
 } from '../../api/userApi'
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useLogin } from '../../login/login-provider';

class MemUsers {
    constructor(pageSize) {
        this.users = []  // in-memory database

        // backend reader state
        this.first = true
        this.lastKey = null
        this.loadPromise = null

        // pagination state
        this.pageSize = pageSize || 10
    }

    getPages() {
        const pages = []
        for (let i = 0; i * this.pageSize < this.users.length; i ++) {
            pages.push(this.users.slice(i * this.pageSize, (i + 1) * this.pageSize))
        }
        return pages
    }

    canFetch() {
        return this.first === true || this.lastKey != null
    }

    load(token) {
        if (this.loadPromise) return this.loadPromise
        if (!this.canFetch()) return new Promise((r) => r(0)) 

        const query = this.first ? null : this.lastKey
        this.loadPromise = userApi_getUserList(token, query)
            .then(({users, last_key}) => {
                // update the in-memory database
                this.users.push(...users)

                // manage state
                this.first = false
                this.lastKey = last_key ?? null
                this.loadPromise = null
                return users.length
            })
        return this.loadPromise
    }

    updateGrant(token, line_id, change, grant) {
        const user = this.users.find((u) => u.line_id === line_id)
        if (!user) return new Promise((r) => r(0)) 

        return userApi_updateGrant(token, line_id, change, grant)
            .then(({grants}) => {
                // update the in-memory database
                user.grants = grants
            })
    }
}

export const UserAdmin = () => {
    const {token} = useLogin()
    const [memUsers] = useState(new MemUsers())
    const [loadProgress, setLoadProgress] = useState('load')
    const [pages, setPages] = useState()
    const [page, setPage] = useState(0)
    const [topMessage, setTopMessage] = useState()

    useEffect(() => {
        let abort = false
        const defer = () => { abort = true }
        if (!loadProgress.startsWith('load')) {
            return defer
        }
        setTopMessage(null)

        // depending on how many records are returned next page may not change
        const nextPage = Math.trunc((memUsers.pageSize * page + pages?.at(page)?.length) / memUsers.pageSize) ?? 0
        memUsers.load(token).then(() => {
            if (abort) return
            setLoadProgress('success')

            const newPages = memUsers.getPages()
            setPages(newPages)
            if (loadProgress === 'loadMore' && page !== nextPage && nextPage < newPages.length) {
                setPage(nextPage)
            }
        }).catch((error) => {
            if (abort) return

            console.warn(`loadUsers failed error: ${error.message}`)
            setLoadProgress('error')
            setTopMessage(error.message)
        })
    
        return defer
    }, [token, memUsers, loadProgress, page])  // eslint-disable-line

    const gotoPage = (targetPage) => {
        return (e) => {
            setPage(targetPage)
            e.preventDefault()
            return false
        }
    }

    const onGrantChange = (grant) => {
        return (e) => {
            setTopMessage(undefined)

            const change = e.target.checked ? 'add' : 'remove'
            const line_id = e.target.id.replace(`grant-${grant}-`, '')
            memUsers.updateGrant(token, line_id, change, grant)
            .then(() => {
                setPages(memUsers.getPages())
            }).catch((error) => {
                console.warn(`updateGrant failed, error: ${error.message}`)
                setTopMessage(error.message)
            })
        }
    }

    return (
        <>
            {topMessage && <span>{topMessage}</span>}
            <Form className='userList'>
                <ul>
                    {(pages?.at(page) || []).map((u, i) =>
                    <li key={i}>
                        <img src={u.avatar_url} alt={u.display_name} />
                        <span>{u.display_name}</span>
                        <Form.Check 
                            id={`grant-member-${u.line_id}`}
                            label={`会員`}
                            checked={u.grants.includes('member')}
                            onChange={onGrantChange('member')}
                        />
                        <Form.Check
                            id={`grant-admin-${u.line_id}`}
                            label={`アドミン`}
                            checked={u.grants.includes('admin')}
                            onChange={onGrantChange('admin')}
                        />
                    </li>)}
                </ul>
            </Form>

            <div className='paging'>
                {pages?.map((p, i) => <Link key={i} onClick={gotoPage(i)} className={page === i ? 'active' : '' }>{i + 1}</Link>)}
                {memUsers.canFetch() && <Link onClick={() => setLoadProgress('loadMore')}>≫</Link>}
            </div>
        </>
    )
}
