import './kokia-news.css';
import Markdown from 'react-markdown'
import {SectionTitle} from './kokia-section';

export const KokiaNewsItem = ({item}) => {
    return <div className="kokia-news-item">
        <div>{item.publishTs}</div>
        <div>
            <Markdown skipHtml>{item.content}</Markdown>
        </div>
    </div>
}

export const KokiaNews = ({news}) =>
<div className="kokia-news-container">
    <SectionTitle title='お知らせ' toLabel='一覧を見る ≫' to='news' />
    <div className="news-items">
        {news.map((item, index) => <KokiaNewsItem item={item} key={index}/>) }
    </div>
</div>