import { useEffect, useState, createContext, useContext } from "react"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import { loginWithToken } from "../api/userApi"


class OpenIdStore {

    onOpenIdChanged(newToken) {
        // console.log(`OpenIdStore token ${newToken ? 'updated' : 'removed'}`)
    }

    isValid(token) {
        if (!token)
            return true

        try {
            const parts = token.split('.')
            return parts.length === 3 && parts[0] !== '' && parts[1] !== '' && parts[2] !== ''
        } catch (e) {
            return false
        }
    }

    get openId() {
        let token = null
        try {
            token = localStorage.getItem('kokiaOpenIdToken')

            if (token && !this.isValid(token)) {
                console.warn(`Invalid openId found in localStorage: ${token} -> return undefined`)
                return undefined
            }

            return token
        } catch (e) {
            console.warn(`Failed to read kokiaOpenIdToken from localStorage value: ${token} -> return undefined`, e)
            return undefined
        }
    }

    set openId(token) {
        if (this.openId === token)
            return

        if (!this.isValid(token)) {
            console.warn('Attempted to overwrite kokiaOpenIdToken with an invalid value: ', token)
            return
        }


        if (token) {
            localStorage.setItem('kokiaOpenIdToken', token)
        } else {
            localStorage.removeItem('kokiaOpenIdToken')
        }

        this.onOpenIdChanged(token)
    }
}

const openidStore = new OpenIdStore()
const LoginContext = createContext(null)
export const useLogin = () => {
    return useContext(LoginContext)
};

export const ProtectedRoute = ({ grant, children }) => {
    const { token, user } = useLogin()
    const location = useLocation()

    const hasGrant = !grant || (grant && user?.grants?.includes(grant))

    if (!token || !hasGrant) {
        return <Navigate to="/" replace state={{ from: location }} />
    }

    return children;
};

let startupPromise = null
const startup = async () => {
    const token = openidStore.openId

    if (!token)
        return undefined // not logged in

    return loginWithToken(token)
        .catch((error) => {
            console.warn(`loginWithToken error: ${error} -> discarding token`)
            openidStore.openId = undefined
            return undefined // not logged in
        })
}

export const LoginProvider = ({ children }) => {
    const [token, setToken] = useState()
    const [user, setUser] = useState()
    const navigate = useNavigate()
    const location = useLocation()
    const [onLoginState] = useState({called: false})

    const onLogin = (loginData) => {
        onLoginState.called = true // signal user login -> cancel startup if still in progress

        const { display_name, avatar_url, grants, open_id } = loginData
        console.log(`'${display_name}' logged in`)

        openidStore.openId = open_id
        setUser({ display_name, avatar_url, grants })
        setToken(open_id)

        const origin = location?.pathname || '/';
        navigate(origin)
    }

    const onLogout = () => {
        const { display_name } = user
        console.log(`'${display_name}' logged out`)

        openidStore.openId = undefined
        setUser(undefined)
        setToken(undefined)

        navigate('/')
    }

    useEffect(() => {
        let abort = false
        if (startupPromise === null) {
            startupPromise = startup()
        }
        startupPromise.then((user) => {
            if (abort || onLoginState.called || !user) {
                return
            }

            const { display_name, avatar_url, grants, open_id } = user
            console.log(`Welcome back '${display_name}'`)

            setUser({ display_name, avatar_url, grants })
            setToken(open_id)

            const origin = location?.pathname || '/';
            navigate(origin)
        })

        return () => { abort = true }
    }, []) // eslint-disable-line

    const value = {
        user: user,
        token: token,
        onLogin: onLogin,
        onLogout: onLogout
    }

    return (
        <LoginContext.Provider value={value}>
            {children}
        </LoginContext.Provider>
    );
}
