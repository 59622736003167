import { Accordion } from 'react-bootstrap';
import { KokiaPageTitle, PageSectionTitle } from '../components/kokia-section';
import './shop.css';
import Markdown from 'react-markdown'
import { regularCourses, limitedCourses } from '../api/courses'

function Shop() {
    return (
        <div className='shop-content'>
            <KokiaPageTitle label='開講講座' />

            <PageSectionTitle title='常設講座' />
            <Accordion alwaysOpen>
                {regularCourses.map((course, index) => 
                    <Accordion.Item eventKey={index} key={index}>
                        <Accordion.Header className={!course.details ? 'hide-button' : ''} disabled>
                            <div className='shop-item-header'>
                                <div className='title'><Markdown skipHtml>{course.title}</Markdown></div>
                                <Markdown skipHtml>{course.short}</Markdown>
                            </div>
                        </Accordion.Header>
                        {
                            !course.details ? null :
                            <Accordion.Body>
                                <Markdown skipHtml>{course.details}</Markdown>
                            </Accordion.Body>
                        }
                    </Accordion.Item>
                )}
            </Accordion>

            <PageSectionTitle title='定員制講座' />
            <Accordion alwaysOpen>
                {limitedCourses.map((course, index) => 
                    <Accordion.Item eventKey={index} key={index}>
                        <Accordion.Header className={!course.details ? 'hide-button' : ''}>
                            <div className='shop-item-header'>
                                <div className='title'><Markdown skipHtml>{course.title}</Markdown></div>
                                <Markdown skipHtml>{course.short}</Markdown>
                            </div>
                        </Accordion.Header>
                        {
                            !course.details ? null :
                            <Accordion.Body>
                                <Markdown skipHtml>{course.details}</Markdown>
                            </Accordion.Body>
                        }
                    </Accordion.Item>
                )}
            </Accordion>
        </div>
    );
}

export default Shop;