import './Welcome.css';
import { loadTop3NewsItems } from "../api/news";
import { KokiaNews } from "../components/kokia-news"
import { useLogin } from '../login/login-provider';
import { SectionTitle } from '../components/kokia-section';

const top3News = loadTop3NewsItems()

const NoticeForMembers = () => (
    <div className='welcome-container'>
        <SectionTitle
            title="会員専用note"
            toLabel="記事を読む ≫"
            to='https://sites.google.com/view/kokia-note' />

        <ul>
            <li>noteの有料記事も全文読めるようにしています。</li>
            <li>システムの関係上、Googleアカウントをお持ちの方のみ閲覧可能です。</li>
        </ul>
    </div>
)

const OneTopicForEveryDay = () => (
    <div className='welcome-container'>
        <SectionTitle
            title="合格までの１日１課題"
            toLabel="記事を読む ≫"
            to='https://sites.google.com/view/kokia-membership-lab' />

        <ul>
            <li>受験日までに必ず取り組んでほしい課題を厳選して掲載しています。</li>
            <li>システムの関係上、Googleアカウントをお持ちの方のみ閲覧可能です。</li>
        </ul>
    </div>
)

const Booking = () => (
    <div className='welcome-container'>
        <SectionTitle
            title="無料相談"
            toLabel="予約はこちら ≫"
            to='https://coubic.com/kokiajuken/3753828/book' />
        
        <ul>
            <li>1ヶ月あたり20分の面談が無料でご利用できます。</li>
            <li>翌月分のスケジュールは、当月25日以降にご確認可能です。
                （例：2月分は、1月25日以降に閲覧可能）</li>
            <li>申し込みは前日のお昼12時まで、キャンセルは直前まで可能です。</li>
            <li>翌月以降への繰越しはできません。
                （例：1月の特典分は1月中にご使用ください。）</li>
        </ul>
    </div>
)

const Schools = () => (
    <div className='welcome-container'>
        <SectionTitle title='学校情報' toLabel='詳細を見る ≫' to='schools' />
        <span>在校生および昨秋の試験で合格した新入生の方にご提出いただいたアンケートを掲載しています。</span>
    </div>
)

function WelcomePage() {
    const { user } = useLogin()
    const isMember = user?.grants?.includes('member')
    return (
        <>
            <div className="app-logo">
                <div className="app-logo-title">願書添削ならお任せください！！</div>
            </div>

            {isMember ? <NoticeForMembers /> : null}
            {isMember ? <OneTopicForEveryDay /> : null}
            {isMember ? <Booking /> : null}
            {isMember ? <Schools /> : null}

            <div className='welcome-container'>
                <KokiaNews news={top3News} />
            </div>
        </>
    );
}

export default WelcomePage;
