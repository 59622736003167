import './Layout.css';
import { Link, Outlet, ScrollRestoration } from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap'
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";

import KokiaBrand from './kokia-brand.svg';
import KokiaLoginImg from './line-bubble.svg';
import AddFriendImage from './line-add-friend.svg';
import { useLogin } from './login/login-provider';
import { useEffect, useState } from 'react';

const LINE_URI = 'https://access.line.me'
const CLIENT_ID = 2002892945;

const doLogin = (e) => {
    // for verification on successful login, sync because the anchor leaves the app
    const lineLoginState = new Date().getTime()
    localStorage.setItem('lineLoginState', lineLoginState)

    if (process.env.NODE_ENV === 'production') {
        e.target.href = `${LINE_URI}/oauth2/v2.1/authorize`
            + `?response_type=code`
            + `&client_id=${CLIENT_ID}`
            + `&redirect_uri=${encodeURIComponent(window.location.origin + '/login')}`
            + `&state=${lineLoginState}`
            + `&scope=${encodeURIComponent('profile openid')}`;
    } else {
        // test mode
        e.target.href = `/login?state=${lineLoginState}`
    }

    return true // true: follow the link
}

const KokiaNavbar = () => {
    const [expanded, setExpanded] = useState(false)
    const { user, onLogout } = useLogin()
    const isAdmin = user?.grants?.includes('admin')

    const closeMenu = (e) => {
        setExpanded(false)
        e.stopPropagation()
        return false
    }

    const logout = () => {
        setExpanded(false)
        onLogout()
    }

    useEffect(() => {
        const isInside = (x, y, r) => (r.left <= x && x <= r.right && r.top <= y && y <= r.bottom)
        const documentClicked = (e) => {
            const toggler = document.querySelector(".navbar-toggler").getBoundingClientRect();
            const togglerClicked = isInside(e.clientX, e.clientY, toggler)
            if (togglerClicked) {
                setExpanded(!expanded);
            }

            const nav = document.querySelector(".navbar-nav").getBoundingClientRect();
            const navClicked = isInside(e.clientX, e.clientY, nav)
            if (expanded && !navClicked) {
                setExpanded(false)
            }
        }
        document.addEventListener('click', documentClicked)
        return () => {document.removeEventListener('click', documentClicked)}
    }, [expanded, setExpanded])


    return (
        <Navbar fixed="top" expand="md" expanded={expanded}>
            <div className="navbar-bg-overlay"></div>
            <Navbar.Brand>
                <LinkContainer to="/">
                    <img src={KokiaBrand} width="320px" alt="Kokia" />
                </LinkContainer>
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className={!expanded && 'd-none'}>
                <Nav>
                    {isAdmin &&
                        <LinkContainer to="/users">
                            <Nav.Link onClick={closeMenu}>ユーザー管理</Nav.Link>
                        </LinkContainer>}
                    {!user ? (
                        <Nav.Link
                            href={`https://access.line.me/oauth2/v2.1/authorize`}
                            onClick={doLogin} >
                            <img src={KokiaLoginImg} className="lineBubble" width="30px" alt="Line" />
                            ログイン
                        </Nav.Link>
                    ) : (
                        <Nav.Link onClick={logout} >
                            ログアウト
                        </Nav.Link>
                    )}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}


const KOKIA_LINE_URL = `https://lin.ee/DXcQJcI`

const Footer = () =>
    <div className="footer">
        <div className="menu-container">
            <div className="button">
                <Link to="intro">教室紹介</Link>
            </div>
            <div className="button">
                <Link to="shop">開講講座</Link>
            </div>
            <div className="button short">
                <Link to="https://ameblo.jp/sakura-sakulabo/" target='_blank'>ブログ</Link>
            </div>
            <div className="button">
                <Link to="achievement">合格実績</Link>
            </div>
            <div className="button shop">
                <Link to="https://sakurailab.base.shop/" target='_blank'>ショップ</Link>
            </div>
        </div>

        <Container>
            <Row className="d-flex align-items-center justify-content-center">
                <Col xs={"auto"}>
                    <p>お問合せはこちら→→</p>
                    <p>LINE相談は無料です</p>
                </Col>
                <Col xs={"auto"} className="d-flex align-items-center">
                    <a href={KOKIA_LINE_URL}>
                        <img src={AddFriendImage} alt="友だち追加" height="48" />
                    </a>
                </Col>
            </Row>
        </Container>
    </div>


function Layout() {
    return (
        <div>
            <KokiaNavbar />

            <div className="page-bg">
                <div className="page-content">
                    <Outlet />

                    <Footer />
                </div>
            </div>

            <ScrollRestoration />
        </div>
    );
}

export default Layout;