import './intro.css';
import { KokiaPageTitle, SectionContent } from '../components/kokia-section';

function Intro() {
    return (
        <>
            <KokiaPageTitle label='教室紹介' />
            <SectionContent contentClass="intro-content">
                <p>
                    はじめまして。コキア受験教室の桜井です。
                </p>
                <p>
                    突然ですが、小学校受験って、本当に不透明だと思いませんか？そして、一度その世界に入ってしまうと抜けるのは非常に難しい。
                </p>
                <p>
                    私自身、二児の母であり、二度の小学校受験を経験しました。当初は全くコネクションもなく、在校生の知り合いもおらず、ひたすら学校のホームページと説明会で学校研究に励む日々・・・家庭学習のやり方も、塾の選び方も、ゼロから試行錯誤して四年間を過ごしました。
                </p>
                <p>
                    我が子の受験終了後、”少しでも後進の方の役に立てるなら”という思いでアドバイザーとしての活動を開始し、最初は私の友人、次の年にはそのまた友人・・・と、いつの間にかご紹介を繋げていただき、今に至ります。
                </p>
                <p>
                    その間、小学校受験のTSUBASA通信教育でも、成蹊小学校の専門講師としてアドバイスや願書添削などを担当させていただいたほか、いくつかの問題集作成にも携わらせていただきました。その経験は、コキアでの私の活動の礎となっております。
                </p>
                <p>
                    また、コキアの立ち上げに際しては、在京の様々な国立・私立小学校に在学中のお母様が協力してくださることになりました。
                </p><p>
                    今後は、最大の強みであるそのネットワークを存分に活用し、最新の情報に基づく最高のサービスを提供させていただきたいと思っております。
                </p><p>
                    コキアが、皆様の一番近い相談相手になれれば幸いです。
                </p>
                <p>
                    コキア受験教室　<br />
                    代表　<span>桜井 香織</span>
                </p>
            </SectionContent>
        </>
    );
}

export default Intro;