import './schools.css'
import { Link, useParams, Navigate } from "react-router-dom";
import {schoolImpressions} from "../api/contentApi"
import { KokiaPageTitle, SectionContent, SectionTitle } from "../components/kokia-section";
import { Accordion } from 'react-bootstrap';
import Markdown from 'react-markdown';

const SchoolList = ({schools}) => (
    <ul className='schoolList'>
        {schools.map((school, index) => (
            <li key={index}>
                {school.name}
                <div>{school.responses.map((resp, rid) => (
                        <Link key={rid} to={`${resp.id}`}>{resp.label}</Link>
                ))}</div>
            </li>
        ))}
    </ul>
)

const SchoolsIndex = ({data}) => {
    const {privateSchools, stateSchools} = data
    return (
        <>
            <KokiaPageTitle label='学校情報' />
            <SectionContent contentClass='schools'>
                <p>
                    在校生および昨秋の試験で合格した新入生の方にご提出いただいたアンケートを掲載しています。
                </p>
                <p>
                    ご厚意で提供いただいた貴重な情報ですので、取り扱いには十分ご注意のうえ、学校研究・志望校選定にお役立てくだされば幸いです。
                </p>
                <p>
                    ※回答内容は、あくまで個人の感想です。
                </p>
                <p>
                    ※情報の権利は全てコキア受験教室に帰属いたします。会員以外への共有等が行われた場合は、然るべき対応を取ることになりますのでご留意ください。
                </p>

                <SectionTitle title="私立" />
                <SchoolList schools={privateSchools} />

                <SectionTitle title="国立" />
                <SchoolList schools={stateSchools} />
            </SectionContent>
        </>
    )
}

const SchoolImpression = ({title, impressions}) => (
    <>
        <KokiaPageTitle label={title} short/>
        <SectionContent contentClass='school-impression'>
            <Accordion alwaysOpen>
                    {impressions.map((impression, index) => 
                        <Accordion.Item eventKey={index} key={index}>
                            <Accordion.Header>
                                <div className='item-header'>
                                    <div className='title'>{impression.title}</div>
                                    <Markdown skipHtml>{impression.short}</Markdown>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <Markdown skipHtml>{impression.details}</Markdown>
                            </Accordion.Body>
                        </Accordion.Item>
                    )}
                </Accordion>
        </SectionContent>
    </>
)

const SchoolInfo = () => {
    const { story } = useParams()

    if (!story) {
        return <SchoolsIndex data={schoolImpressions.index} />
    }

    const details = schoolImpressions[story]
    if (story && !details) {
        return <Navigate to="/schools" replace />
    }

    return <SchoolImpression
        title={details.title}
        impressions={details.impressions}
    />
}

export default SchoolInfo;