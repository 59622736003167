import './achievement.css';
import { KokiaPageTitle, SectionContent } from '../components/kokia-section';

function Achievements() {
    return (
        <>
            <KokiaPageTitle label='合格実績' />
            <SectionContent>
                <div className='achievement-list'>
                    <div className='ach-header'>2023年秋</div>
                    <div className='item'>
                        <div>成蹊小学校</div><div>6名</div>
                    </div>
                    <div className='item'>
                        <div>早稲田実業学校初等部</div><div>2名</div>
                    </div>
                    <div className='item'>
                        <div>暁星小学校</div><div>2名</div>
                    </div>
                    <div className='item'>
                        <div>東京農業大学稲花小学校</div><div>2名</div>
                    </div>
                    <div className='item'>
                        <div>筑波大学附属小学校</div><div>2名</div>
                    </div>
                    <div className='item'>
                        <div>聖心女子学院初等部</div><div>1名</div>
                    </div>
                    <div className='item'>
                        <div>横浜雙葉小学校</div><div>1名</div>
                    </div>
                    <div className='item'>
                        <div>桐朋学園小学校</div><div>1名</div>
                    </div>
                    <div className='item'>
                        <div>桐蔭学園小学校</div><div>1名</div>
                    </div>
                    <div className='item'>
                        <div>浦和ルーテル学院小学校</div><div>1名</div>
                    </div>
                    <div className='item'>
                        <div>開智小学校</div><div>1名</div>
                    </div>
                    <div className='item'>
                        <div>開智所沢小学校</div><div>1名</div>
                    </div>
                    <div className='item'>
                        <div>精華小学校</div><div>1名</div>
                    </div>
                    <div className='item'>
                        <div>学習院初等科</div><div>1名</div>
                    </div>
                    <div className='ach-remark'>
                        ※ 願書添削・個別相談・各種講座受講者のみの実績です。
                    </div>
                </div>
            </SectionContent>
        </>
    );
}

export default Achievements;