export const schoolImpressions = {
    index: {
        privateSchools: [
            {
                name: '浦和ルーテル学院小学校',
                responses: [{ label: '在校生', id: 'urawa_1'}]
            },{
                name: '学習院初等科',
                responses: [{ label: '在校生', id: 'gakushuin_1'}]
            },{
                name: '暁星小学校',
                responses: [{ label: '新入生', id: 'gyousei_1'}]
            },{
                name: '国立学園小学校',
                responses: [{ label: '在校生', id: 'kkgakuen_1'}]
            },{
                name: '成蹊小学校',
                responses: [
                    { label: '新入生', id: 'seikei_1'},
                    { label: '在校生', id: 'seikei_2'}
                ]
            },{
                name: '聖心女子学院初等科',
                responses: [{ label: '新入生', id: 'scheart'}]
            },{
                name: '⻄武学園文理小学校',
                responses: [{ label: '新入生', id: 'seibu_1'}]
            },{
                name: '桐蔭学園小学校',
                responses: [{ label: '新入生', id: 'toin_1'}]
            },{
                name: '東京三育小学校',
                responses: [{ label: '在校生', id: 'tyosaniku_1'}]
            },{
                name: '桐朋学園小学校',
                responses: [{ label: '新入生', id: 'toho_1'}]
            },{
                name: '文教大学附属小学校',
                responses: [{ label: '新入生', id: 'bunkyo_1'}]
            },{
                name: '星野学園小学校',
                responses: [{ label: '新入生', id: 'hoshino_1'}]
            },{
                name: '森村学園初等部',
                responses: [{ label: '新入生', id: 'morimura_1'}]
            },{
                name: '横浜雙葉小学校',
                responses: [{ label: '新入生', id: 'yfutaba_1'}]
            },{
                name: '立教小学校',
                responses: [{ label: '在校生', id: 'rikkyo_1'}]
            },{
                name: '立教女学院小学校',
                responses: [
                    { label: '在校生', id: 'rikkyojoshi_1'},
                    { label: '在校生', id: 'rikkyojoshi_2'}
                ]
            },{
                name: '早稲田実業学校初等部',
                responses: [
                    { label: '新入生', id: 'waseda_1'},
                    { label: '在校生', id: 'waseda_2'}
                ]
            }
        ],
        stateSchools: [
            {
                name: '筑波大学附属小学校',
                responses: [{ label: '在校生', id: 'tsukuba_1'}]
            },{
                name: '東京学芸大附属大泉小学校',
                responses: [{ label: '在校生', id: 'gakugeioizumi_1'}]
            },
        ]
    },
    urawa_1: {
        title: '浦和ルーテル学院小学校(在校生)',
        impressions: [
            {
                title: '当校のどこに魅力を感じて受験したか',
                details: '宗教教育、小中高一貫教育に魅力を感じて受験しました。'
            },{
                title: '当校の学校研究はどうやって行なったか',
                details: `
学校説明会や公開授業に伺い、直接自分の目で見て、子どもに合う学校かを見極めました。  
学校研究は、ジャックに通室していたので、ジャックの入試問題情報集がとても役に立ちました。
`
            },{
                title: '当校の学校訪問で見ておいた方がいいポイントはどこか',
                details: '受験生の保護者向けにある学校公開があるので、実際に通っている生徒のリアルな様子を見ることができて非常 に参考になりました。'
            },{
                title: '当校の願書を書く時に特に心がけたことは何か',
                details: 'ルーテルは、建学の精神について理解がある家庭を求めていると感じます。そのため、ルーテルの教育方針と我 が家の教育方針の共通する部分に焦点を当てて書くことを心がけました。'
            },{
                title: '当校の面接の雰囲気はどうだったか',
                details: 'いくつか受験した学校の中でも、特に面接時間が⻑かったです。(約 20 分)親も子も、色々と質問されました。 面接の雰囲気は終始和やかで、子どもに対しても優しくわかりやすく質問してくれて、とても話しやすい雰囲気 でした。'
            },{
                title: '当校の合格のため、特にしておいた方が良いことなどあるか',
                details: 'ルーテルのペーパー試験の訂正が、斜め二重線ではなく、バツ印で訂正するので、そのやり方には慣れておいた 方が良いです。また、ルーテルの試験では、自分の名前をひらがなで書かせるので、お名前の練習もしておいた 方がいいかと思います。'
            },{
                title: '入学して良かったと思うのはどんなところか',
                details: '先生方が子ども達のことを非常に手厚く見てくれます。全体的に面倒見の良い学校だと思います。小中高一貫校 で、同じ校舎内に中高生もいるので、電車遅延など何かがあったときも助けてくれます。 また、学校内にあるアフタースクールもとても充実しており、良いです。'
            },{
                title: '入学してから思っていたのと違う点はあるか',
                details: 'ほとんどありません。ひとつだけ挙げるとすると、入学後、毎週日曜日に近所の教会で行われているキリスト教 礼拝への参加について、なるべく出席するように言われます。また出席したかどうかを毎月学校へ提出するカー ドがありますので出席率は学校も見ていると思います。'
            },{
                title: '保護者の雰囲気などはどうか',
                details: 'おおらかで、温厚で、優しい方が本当に多いと感じます。'
            },{
                title: 'どんなお子様が多いと感じるか',
                details: '保護者の雰囲気と被りますが、男女共に、おおらかで優しく、おっとりとした子が多いです。'
            },{
                title: '学校に出向く頻度はどのくらいか',
                details: '1 年生の 1 学期は出向く頻度がとても多かった(4〜5 回くらい)ですが、基本的には学期ごとに 2〜3 回くらい です。'
            }
        ]
    },
    gakushuin_1: {
        title: '学習院初等科(在校生)',
        impressions: [
            {
                title: '当校のどこに魅力を感じて受験したか',
                details: '基礎基本を大切に、日本語教育を第一に考えているところ。都心でありながら広い校庭や池や野菜を育てる庭が あるところ。'
            },{
                title: '当校の学校研究はどうやって行なったか',
                details: '学校説明会や見学会への参加。知り合いに話を聞く。(運動会や初等科祭は非公開)'
            },{
                title: '当校の学校訪問で見ておいた方がいいポイントはどこか',
                details: '学校全体の雰囲気。特に講堂は趣があり、歴史の深さを感じます。見学会は教室にも入れるので掲示物をよく見 ると、生徒の雰囲気がわかると思います。'
            },{
                title: '当校の願書を書く時に特に心がけたことは何か',
                details: '家庭の中でも基礎基本を大事にしていることや、他人を思いやる気持ちを大切に育てているエピソードを書きま した。'
            },{
                title: '当校の面接の雰囲気はどうだったか',
                details: '待合室から張り詰めた空気があり、とても緊張しました。面接をされる先生にもよると思いますが、全く表情を かえない先生でしたので感触があるのかわかりづらかったです。テーブルに華やかなテーブルクロスがかかって いたり、イスも重厚なものでしたので、他校とは印象がかなり違いました。'
            },{
                title: '当校の合格のため、特にしておいた方が良いことなどあるか',
                details: '難しいことよりも、基本的なこと(運筆やはさみの使い方など)、運動でしたらケンステップや片足バランスなど をきちんとこなしていくのが大切だと感じました。 また、先生の話をきちんと目を見て聞くというのも、基本的すぎますが大切だと思います。'
            },{
                title: '入学して良かったと思うのはどんなところか',
                details: '先生方が、生徒に対しても親に対してもとても丁寧なところ。叱る時はきちんと叱ってくれます。 また、きちんと子どもの話を聞いてくれます。'
            },{
                title: '入学してから思っていたのと違う点はあるか',
                details: '大人しい子が多いのかと思っていましたが、逆に活発で自分の意見をハキハキ言う子が多いという印象があります。'
            },{
                title: '保護者の雰囲気などはどうか',
                details: '皆さん丁寧で礼儀正しいです。とにかく皆さん良い方ばかりで、色々と教えていただいています。 兄弟姉妹で通わせている方もやはり多いので、色々な情報をもらえます。'
            },{
                title: 'どんなお子様が多いと感じるか',
                details: '上にも書きましたが活発なお子様(女の子も)が多い印象です。しかしきちんとする時はする、メリハリがあると 思います。あとみなさん(特に女の子は)小学生低学年でも字が上手で驚きます。'
            },{
                title: '学校に出向く頻度はどのくらいか',
                details: '月一回、多いときで2回ほどあり、それに加えて授業参観がある時もありますので、他校に比べると多い方かと 思います。役員になられた方はもっとあると思います。(私はまだ役員になったことがないのでわかりませんが ...)'
            }
        ]
    },
    gyousei_1: {
        title: '暁星小学校(新入生)',
        impressions: [
            {
                title: '当校のどこに魅力を感じて受験したか',
                details: '私が医師であることもあり、医学部進学率が良いこと。数少ない男子一貫校であり校則や躾が厳しく、一生の友 達を作ってもらいたいと思ったこと。'
            },{
                title: '当校の学校研究はどうやって行なったか',
                details: '説明会、運動会。また、同僚が暁星卒で子供も在学中のため、雰囲気はもちろん内部の情報もいただき、とても 助かりました。'
            },{
                title: '当校の学校訪問で見ておいた方がいいポイントはどこか',
                details: '清掃が行き届いた校内、キビキビとした生徒。'
            },{
                title: '当校の願書を書く時に特に心がけたことは何か',
                details: '今年のテーマは「時間配分」でした。受験という目標のために、子供と同じ方向を向き、一緒に頑張る過程を大 切にしていること、子育ては趣味であり一番の喜びにしていると書きました。'
            },{
                title: '当校の面接の雰囲気はどうだったか',
                details: '「歓喜」と「癇癪」の違いを具体的なエピソードとともに説明する(これは画用紙に書かれたものを見せられま したので、全員に確認していると思われます)。 他は、カトリック教育についてのことが中心でした。和やかな雰囲気でした。'
            },{
                title: '当校の合格のため、特にしておいた方が良いことなどあるか',
                details: 'ペーパー難関校としての対策はもちろんですが、息子の話を聞くと、行動観察や待機時の態度を厳しくチェック しているようでした(2次でおしゃべりをしていた子は先生に連れて行かれて、最後まで戻って来なかったよう です)。'
            }
        ]
    },
    kkgakuen_1: {
        title: '国立学園小学校(在校生)',
        impressions: [
            {
                title: '当校のどこに魅力を感じて受験したか',
                details: '全員が中学受験をする点'
            },{
                title: '当校の学校研究はどうやって行なったか',
                details: '説明会、幼児教室からの情報'
            },{
                title: '当校の学校訪問で見ておいた方がいいポイントはどこか',
                details: '在学生の様子、施設'
            },{
                title: '当校の願書を書く時に特に心がけたことは何か',
                details: '覚えておりません'
            },{
                title: '当校の面接の雰囲気はどうだったか',
                details: 'コロナ禍の為 zoom で校⻑と行いましたが、アットホームな雰囲気でした。'
            },{
                title: '当校の合格のため、特にしておいた方が良いことなどあるか',
                details: '平均台、知育パズルなど'
            },{
                title: '入学して良かったと思うのはどんなところか',
                details: '異学年との交流機会があること、全員受験をするので切磋琢磨できること'
            },{
                title: '入学してから思っていたのと違う点はあるか',
                details: '中学受験校のため英語教育に力を入れておらず、ネイティブ教師もいません。各自が英検 Jr.を自宅で進める状況 です。教師の質にムラがある点も少し残念です。'
            },{
                title: '保護者の雰囲気などはどうか',
                details: '共働きの方も多くカジュアルな雰囲気'
            },{
                title: 'どんなお子様が多いと感じるか',
                details: '溌剌としている子が多い'
            },{
                title: '学校に出向く頻度はどのくらいか',
                details: '一年間に、参観日 2 回、運動会、しらかば祭、保護者会 3 回、面談 2 回(希望者のみ) 他:講演会、懇親会などのイベントは参加自由'
            }
        ]
    },
    seikei_1: {
        title: '成蹊小学校(新入生)',
        impressions: [
            {
                title: '当校のどこに魅力を感じて受験したか',
                details: '「逞しい実践力」のスローガンを裏付ける、様々な行事への取り組みを知り、こちらの学校でなら人間らしく思 いやりがあり、力強い、魅力的な人物に成⻑してくれるのではないかと感じたので受験しました。'
            },{
                title: '当校の学校研究はどうやって行なったか',
                details: '年少の頃から学校の説明会など、とにかく足を運びました。'
            },{
                title: '当校の学校訪問で見ておいた方がいいポイントはどこか',
                details: '子ども達の姿。児童達の面倒見が良く、素敵だなぁと思うお子さんが大勢いる学校でした。'
            },{
                title: '当校の願書を書く時に特に心がけたことは何か',
                details: '第一志望という熱意が伝わるように心がけました。'
            },{
                title: '当校の面接の雰囲気はどうだったか',
                details: '3 名いらっしゃいました。穏やかな雰囲気でした。'
            },{
                title: '当校の合格のため、特にしておいた方が良いことなどあるか',
                details: '親は過去問を早く確認しておくと良いと思います。ペーパーの難易度が驚くほど難しく、我が家の場合ですが、 試験間際になってからのペーパー学習では間に合わなかったと思います。 桜井先生に模試の結果を見ていただき、学習の仕方などを相談し、願書添削もしていただきました。桜井先生の アドバイスがなければ我が家の合格はなかったと思っております。'
            }
        ]
    },
    seikei_2: {
        title: '成蹊小学校(在校生)',
        impressions: [
            {
                title: '当校のどこに魅力を感じて受験したか',
                details: '児童が積極的に授業に参加している様子、先生の児童に対する声がけを拝見し、日頃からの良い関係性が見てと れました。ここでなら、息子が学ぶことの楽しみを知ってくれるものと確信したため。'
            },{
                title: '当校の学校研究はどうやって行なったか',
                details: '学校には年少時より何度も足を運びました。説明会だけでなく、学園のイベント全てにできる限り夫婦で分担し て参加するようにしました。在校生のお母様にも、会うたびに色々と話を伺いました。 また、学校の発行している本を読み、学校が大切にしていることの本質を正確に落とし込めるように心がけまし た。'
            },{
                title: '当校の学校訪問で見ておいた方がいいポイントはどこか',
                details: '授業の雰囲気。学校の中心にある素晴らしい図書館。'
            },{
                title: '当校の願書を書く時に特に心がけたことは何か',
                details: '本校のどこに魅力を感じ、どれほど強い思いで志願しているかを伝えるように心がけました。'
            },{
                title: '当校の面接の雰囲気はどうだったか',
                details: `
人にもよるらしいですが、我が家の場合は、終始和やかでした。全くご縁のない学校だったので厳しい面接を覚 悟しておりましたが、笑いもあり、「温かいご家庭だということがよく分かりました。」「強い思いがしっかり伝 わりました。こんなにも興味を持っていただいてありがとうございます。」と言っていただき、涙が出るほど嬉し かったのを覚えています。  
第一志望の方は、とにかくそれがしっかり伝わるように練習してください。`
            },{
                title: '当校の合格のため、特にしておいた方が良いことなどあるか',
                details: 'ペーパー力は必須です。運動も、出るものが決まっているのでしっかりと練習しました。 試験は、「先生の話をよく聞いて行動できるか」「メリハリをつけた行動ができるか」を試される内容が多いので、 日頃からよく言い聞かせておくと良いと思います。また、当日楽し過ぎてふざけ出す男子が必ずいるので、一緒 にふざけないことを予め伝えておくと良いと思います。'
            },{
                title: '入学して良かったと思うのはどんなところか',
                details: `
とにかく子どもが楽しそうです。「これ知ってる?」と私も知らない知識を仕入れてくることが多くなり、たくさ んの学びを与えていただいていると感謝しかありません。 トラブルがあった時にも、先生方は言い訳などせずに正面から受け止めて、親身になって間に入ってくれます。 トラブルはどこの学校でも起こることですが、そういった誠意ある対応のお陰で保護者同士の関係がとても良好 です。  
また、ダメなものはダメだと、先生方が厳しく叱ってくださいます。先生により程度の差はあれ、事なかれ主義 という風潮はまずありません。責任を持って一緒に我が子を育ててくださっていると感じます。`
            },{
                title: '入学してから思っていたのと違う点はあるか',
                details: '行事が多い。思っていた以上に多い。あと、本当によく山に登っています。体力がついて何よりです。'
            },{
                title: '保護者の雰囲気などはどうか',
                details: 'いい意味でラフです。例えば、校内着が破れていたとしても「どうせまたすぐ破けるから、もう今学期はこれで 良いやと思って。笑」みたいな感じで気にしていません。親子ともに「こうじゃなきゃ」ということもなく、い い意味で人は人という感じです。距離感が最高。'
            },{
                title: 'どんなお子様が多いと感じるか',
                details: '好奇心旺盛で、新しいものに「やってみたい!」「もっと知りたい!」と反応できる子は向いていると思います。 運動が苦手でも問題ないですが、嫌いだとしんどいカリキュラムかなと思います。 得手不得手はもちろんありますが、地頭のいいお子様が多いなと感じます。親御さんも熱心な方が多いです。 勉強に熱心でないご家庭は超人的に運動をしているなど、どこかで芽を出すように育てられている気がします。 上級生は下級生の面倒を見る風土が根付いています。本人たちも、そういうものだと思っています。 保護者同様、人は人というか、人を妬むことなく認め合っています。先生方が、そのように育ててくださってい ると感じます。男子も女子も、小グループで遊ぶというより皆で遊んでいます。'
            },{
                title: '学校に出向く頻度はどのくらいか',
                details: `
行事が多いので、出向く回数は多いです。平均すると最低でも 1 ヶ月に1回はあると思います。特に月木に集中 しています。ただ、全て見学必須ではないので、お子様と話がつけば参加は自由です。(ほとんどお会いしない方 もいます。)  
また、入学当初は、4月27日ごろまで送迎が必要です。`
            }
        ]
    },
    scheart: {
        title: '聖心女子学院初等科(新入生)',
        impressions: [
            {
                title: '当校のどこに魅力を感じて受験したか',
                details: `
1. 学校の教育方針(特に世の中の為に動ける他人に優しく、信念を持った女性になって欲しいという点)
2. 説明会で先生方と直接会話させていただいた時の対応(キリスト教の学校を見ていたのでどこの先生も雰囲気 が柔らかい方が多かったのですが聖心の先生方はその中でも物腰柔らかく生徒のことを話す時にとても愛を感 じられました)
3. 生徒方の雰囲気、対応 例えば...文化祭で娘と地図を見ながら困っていたら学生さんが私達に気付き、「間違っていたらすみません、困 っていませんか?良かったらご案内します。」と声を掛けてくれました。こんな素敵なお姉さんになって欲しい なと心から思いました。
4. 親御さんの雰囲気 実際はわかりませんが、説明会に来る人も在学中の人も派手な方などは少ないように感じました。
`
            },{
                title: '当校の学校研究はどうやって行なったか',
                details: `
1. 学校やお教室が開催していた説明会、文化祭に参加
2. 通学している生徒のお母様との面談(お教室に来て話をしてくれた方から学校、試験についてお話を聞きまし た)
3. お教室の聖心ゼミへの参加
4. 学校のパンフレットや HP の確認`
            },{
                title: '当校の学校訪問で見ておいた方がいいポイントはどこか',
                details: `
1. 可能であれば学校で過ごしている生徒の様子
2. 掲示物`
            },{
                title: '当校の願書を書く時に特に心がけたことは何か',
                details: `
1. 教育方針(学校とずれがないか)
2. 学校が求めている内容になっているか
3. 家庭や娘の事、我が家の想いが伝わる内容になっているか`
            },{
                title: '当校の面接の雰囲気はどうだったか',
                details: '終始優しく答えやすかったです。特に子供に対しては柔らかく緊張をほぐしてくれるような笑い掛け，問いかけ をしてくれました。 待合室も監視されている感じはありませんでしたが教室に何組か待機していて重々しい雰囲気はあるので折り 返しを娘と折って娘が緊張しないように努めました。 娘が折れる事を知っていたので手裏剣の折り方を教えてくれる?と言って 2 人で折りました。'
            },{
                title: '当校の合格のため、特にしておいた方が良いことなどあるか',
                details: `
1. プリントを書く時(○や×)塗り絵、制作の丁寧さを日々話していました。
2. 凛とした雰囲気が大事と言われていたので待つ時の姿勢(お教室のクラスの中では、年⻑の夏から姿勢チャン ピオンと言われる様になりました)
3. 説明会への参加(聖心へは学校へ行くたびに愛が募りました!)`
            }
        ]
    },
    seibu_1: {
        title: '⻄武学園文理小学校(新入生)',
        impressions: [
            {
                title: '当校のどこに魅力を感じて受験したか',
                details: '勉強に力を入れている環境だが、無理をさせるのではなく、成⻑に寄り添ってくれていると感じました。'
            },{
                title: '当校の学校研究はどうやって行なったか',
                details: '学校説明会(模試の会場としてもよく使われているので、説明を聞く機会も多かったです)'
            },{
                title: '当校の学校訪問で見ておいた方がいいポイントはどこか',
                details: '学校訪問の際に話してくださる教育内容に注目するといいと思います。 3月ごろの説明会に行ったのですが、廊下一面に卒業生の 1 年時から 6 年時までのスナップ写真が貼ってありま した。6年間の成⻑を大切に見守ってくださる学校だと感じました。 また、地元に友達がいない卒業生のために、20歳の集いも開催してくださるようです。その記念品が体育館に 置いてありました。素敵だなと思いました。'
            },{
                title: '当校の願書を書く時に特に心がけたことは何か',
                details: '他の受験校を書かされますが、第一志望でないことは正直にお伝えして大丈夫です。第一志望は国立、第二志望 が当校であると記載しました。'
            },{
                title: '当校の面接の雰囲気はどうだったか',
                details: '圧迫ではないものの、少し緊張感のある面接でした。初めての面接だったこともありますし、本当に基本的なこ とを聞かれたため和む間がなかったのかも知れません。'
            },{
                title: '当校の合格のため、特にしておいた方が良いことなどあるか',
                details: '学校の教育について、どのような教育が魅力的だと思うか纏めていかれるといいと思います。第一志望で受け る方は少ないものの、それでも「(第一志望が残念だった時は)是非お世話になりたいと思っている」という気 持ちをしっかりとお伝えしてくるといいと思います。'
            }
        ]
    },
    toin_1: {
        title: '桐蔭学園小学校(新入生)',
        impressions: [
            {
                title: '当校のどこに魅力を感じて受験したか',
                details: `
1. 教育方針(学力、体力だけでなく人格形成に重点を置いている点)
2. 時代の流れに沿った改革をしている(お教室の先生から改革がいい方向に成功していると伺いました)
3. 大学の進学先(進学コースに入れた場合)`
            },{
                title: '当校の学校研究はどうやって行なったか',
                details: `
1. 説明会(録音を聞く)
2. 学校の HP、パンフレット

やった方が良かった事→インスタの確認`
            },{
                title: '当校の学校訪問で見ておいた方がいいポイントはどこか',
                details: '志望校として意識したのが遅く、説明会に一度参加しただけでした。面接で説明会の参加や HP を見た事はある かと聞かれました。説明会で「副校⻑がおっしゃられていた〜に深く共感した」という話をした時に、笑いなが ら頷いて貰えました。また、HP で「〜写真の児童の皆さんがとても楽しそうで素敵でした」という話をしまし たら、「見ていただきありがとうございます」と言っていただきました。'
            },{
                title: '当校の願書を書く時に特に心がけたことは何か',
                details: `
1. 学校の方針への理解
2. 家庭の方針(学校との共通点や志望理由に絡める)
3. 将来子どもにどうなって欲しいか→だから桐蔭に入学したいに繋げました。`
            },{
                title: '当校の面接の雰囲気はどうだったか',
                details: `
とても和やかで質問もテンポ良く、先生も沢山会話して下さったので話しやすかったです。私たちの解答に賛同 して下さり(娘の習い事に関してや娘の性格など)、学校の考えなども先生の方から話して下さり、桐蔭で学ばせ たらとても楽しい小学校生活を娘は送れるに違いないと面接で感じました。  
面接は、二つの応接室でそれぞれ同時に行われました。我が家は副校⻑がお一人で対応して下さいました。`
            },{
                title: '当校の合格のため、特にしておいた方が良いことなどあるか',
                details: `
面接のところにも書いた通り、学校の HP や説明会では、学校としてとても重要なことを説明されているのだと 思います。ぜひお目通しください。  
ここ何年か、桐蔭では 6 つのキーコンピテンシーを掲げており、面接でも問われました。(夫婦それぞれ「ここ 最近で成⻑したと感じた事は?」という問いの答えに対し、「では、それを 6 つのキーコンピテンシーに当ては めるとなんだと思われますか?」と聞かれ、これを夫婦でそれぞれビシッと答えられたのがとても喜ばれました。 また、我が家の場合ですが、これは副校⻑だけかもしれないのですが勉強以外に何か芸術系の習い事もひとつ言 えるといいかもしれません。習い事を答えた時に、お教室、絵画、新体操、ピアノ、茶道、プール、楽器遊びを 答えたのですが新体操は団体ですか?と突っ込まれて尋ねられました。「絵画、ピアノ、新体操(ダンスが良かっ た様ですが)は言語が通じなくても凄さが分かり合える素晴らしい物です。そういった感性を育てる、触れさせ る事が大事だと思っております。いい習い事をやっていますね。」と、とても絶賛されました。 本当に素敵な学校だと思います。`
            }
        ]
    },
    tyosaniku_1: {
        title: '東京三育小学校(在校生)',
        impressions: [
            {
                title: '当校のどこに魅力を感じて受験したか',
                details: '少人数制により全ての生徒に先生の目が行き届く。国際色豊かなところ、毎日オリジナルの英語授業あり聖書 も英語で学ぶところ。'
            },{
                title: '当校の学校研究はどうやって行なったか',
                details: 'コロナ時代のため、ホームページやパンフレットを活用。'
            },{
                title: '当校の学校訪問で見ておいた方がいいポイントはどこか',
                details: '教室や廊下に貼ってある生徒の作文や制作物。体育館(図工室、音楽室、家庭科室)へ行くときに渡り廊下が ないところ。'
            },{
                title: '当校の願書を書く時に特に心がけたことは何か',
                details: '教育方針、⻑所短所の欄が広く、具体例も含めて埋めるのが大変だったので、自慢しすぎることなく教育方針 を踏まえて伝えること。'
            },{
                title: '当校の面接の雰囲気はどうだったか',
                details: 'はじめに子どもに一問一答形式で 15 問程度。その後、両親への質問はどちらが答えても OK。「お父さんお母 さんの役割、一番大事にしていること」という質問がありますよー、と学校説明会等の時に教えてくれまし た。担当先生方が厳粛な感じだったので、その雰囲気のまま終わった気がします。'
            },{
                title: '当校の合格のため、特にしておいた方が良いことなどあるか',
                details: '信者でなくとも、キリスト教への理解を示したほうが良いと思います。'
            },{
                title: '入学して良かったと思うのはどんなところか',
                details: '心の教育のおかげで自己肯定感を高められ、人間性が安定しクラス環境が整っている。'
            },{
                title: '入学してから思っていたのと違う点はあるか',
                details: '思っていた以上に宗教色が強いところ。'
            },{
                title: '保護者の雰囲気などはどうか',
                details: 'とてもカジュアルな方が多いが、中学受験する方も大半なので話しやすい。'
            },{
                title: 'どんなお子様が多いと感じるか',
                details: '運動が得意で優しい子が多い。'
            },{
                title: '学校に出向く頻度はどのくらいか',
                details: '行事や面談などで月1回程度、加えてクラス役員になると役員会年 5 回、6 年間で一度は役員をする。'
            }
        ]
    },
    toho_1: {
        title: '桐朋学園小学校(新入生)',
        impressions: [
            {
                title: '当校のどこに魅力を感じて受験したか',
                details: '説明会に参加した時に、先生と生徒の絆や、一生の仲間と出会い、お互い尊重し合っている様子を強く感じ感銘 を受けました。'
            },{
                title: '当校の学校研究はどうやって行なったか',
                details: '学校説明会、校舎見学、大手塾の学校別研究会に参加しました。コロナ前は運動会の見学に参加しました。 在校生保護者からの話も少し伺いました。'
            },{
                title: '当校の学校訪問で見ておいた方がいいポイントはどこか',
                details: '先生の子供達への接し方。在校生がいる時間であれば児童のみなさんの様子。校内の展示作品。'
            },{
                title: '当校の願書を書く時に特に心がけたことは何か',
                details: '訪問をした際に惹かれたエピソードと、子どもの特徴を踏まえて、入学したらどのように育っていってほしいか を校風も鑑みて書きました'
            },{
                title: '当校の面接の雰囲気はどうだったか',
                details: '面接なし'
            },{
                title: '当校の合格のため、特にしておいた方が良いことなどあるか',
                details: '集中力、子どもらしさ、素直さを大切にして受験準備で子どもの個性を潰さないこと。制作の出来ではなく、そ の子そのものの個性を見ていただけた印象です。'
            }
        ]
    },
    bunkyo_1: {
        title: '文教大学附属小学校(新入生)',
        impressions: [
            {
                title: '当校のどこに魅力を感じて受験したか',
                details: '校⻑先生の子供に対する愛情深さ'
            },{
                title: '当校の学校研究はどうやって行なったか',
                details: '実際学校の近くまで行ったり、在校生の知り合いに聞いたりしました。特に、在校生の知り合いには話をたくさ ん聞きました。'
            },{
                title: '当校の学校訪問で見ておいた方がいいポイントはどこか',
                details: '校⻑先生のお話 わからないことはすぐ調べられるように、学校の真ん中(教室を出るとすぐのところ)に本がたくさんあります。 子どもたちのことをとく考えられた造りになっており、そういう部分も見ておくと良いかと思います。'
            },{
                title: '当校の願書を書く時に特に心がけたことは何か',
                details: '願書の欄がとても少ないので、志願理由を簡潔かつ明確に伝わるように心がけました。'
            },{
                title: '当校の面接の雰囲気はどうだったか',
                details: '何度かお会いしてお話しする機会もあったのですが、面接では改めてしっかりと本気度を見られるような質問を されたと思います。どんな質問でも笑顔で見守って頂き、非常に安心して受けられた面接でした。'
            },{
                title: '当校の合格のため、特にしておいた方が良いことなどあるか',
                details: '小規模の学校で先生方も覚えてくれるので、説明会にも何度も参加したり、先生とお話する機会を作ると良いか と思います。ホームページに過去問なども載せてあるので、チェックするのもいいと思います。'
            }
        ]
    },
    hoshino_1: {
        title: '星野学園小学校(新入生)',
        impressions: [
            {
                title: '当校のどこに魅力を感じて受験したか',
                details: '先生方が児童のことをすごく大切に丁寧に育ててくださっているところ。 学校の授業もとても丁寧で、力を伸ばしてくれそうなところ。'
            },{
                title: '当校の学校研究はどうやって行なったか',
                details: '学校説明会(覚えてくださるほど何度も通いました。)'
            },{
                title: '当校の学校訪問で見ておいた方がいいポイントはどこか',
                details: '児童がすごく自然に挨拶をしっかりしてくれるところ。もちろん教育にも力を入れていますが、しっかりと人間 教育に力を入れていると感じます。素直で面倒見もいい、擦れていないお子様が多い印象があります。'
            },{
                title: '当校の願書を書く時に特に心がけたことは何か',
                details: '学業面だけでなく、人間性も磨かせていただきたいという点を強く伝えました。'
            },{
                title: '当校の面接の雰囲気はどうだったか',
                details: '訪問回数で本気度が伝わっていたようです。しっかりと訪問記録が残されていました。とても和やかでした。'
            },{
                title: '当校の合格のため、特にしておいた方が良いことなどあるか',
                details: '都内から埼玉を受験すると、どうしても志望度が低いと思われてしまうと思いますので、滑り止めであったと しても、しっかりと誠意を見せられるように何度も訪問されるといいと思います。'
            }
        ]
    },
    morimura_1: {
        title: '森村学園初等部(新入生)',
        impressions: [
            {
                title: '当校のどこに魅力を感じて受験したか',
                details: '広い敷地の中で自然に囲まれ、のびのびと過ごせるところ。 また先生方が子供と関わること、教えることをとても楽しいとおっしゃっていたことがとても印象的で受験しま した。'
            },{
                title: '当校の学校研究はどうやって行なったか',
                details: '塾の先生に聞いたり、何度か学校見学にいきました。ホームページや SNS にも力を入れているのでそういうの も見ておりました。'
            },{
                title: '当校の学校訪問で見ておいた方がいいポイントはどこか',
                details: '個人的には掲示物などより先生方の一言一言をちゃんと聞いていた方が良いかと思います。先生方がどれだけ教 えることに対しワクワクしているかが感じられます。 また自然多く、広いので至る所にたくさんの生き物が生息しています。ここで過ごすイメージができるかなと思 いますので。'
            },{
                title: '当校の願書を書く時に特に心がけたことは何か',
                details: '志願理由を書く欄と別に、その他で志願者から学園に伝えたいことを書く欄があります。そこに、印象的だった ことや感動したことをしっかり書くように意識しました。'
            },{
                title: '当校の面接の雰囲気はどうだったか',
                details: '事前アンケートを元に色々聞いてくださりとても和気藹々としていてやりやすかったです。'
            },{
                title: '当校の合格のため、特にしておいた方が良いことなどあるか',
                details: 'ペーパーは量も割とあるのでしっかり対策しておいた方がいいかなと思います。横浜慶應と併願する方が多いの で。'
            }
        ]
    },
    yfutaba_1: {
        title: '横浜雙葉小学校(新入生)',
        impressions: [
            {
                title: '当校のどこに魅力を感じて受験したか',
                details: '受験日程・通学時間・展示会での作品(手芸品など贈る相手を思って作ったのが伝わってきた)・キリスト教(仏教 は子供に対する宗教教育がない為。もし両親が事故などで急逝した場合、神様が拠り所になると感じた為)・図書 館(本好きの子が集まる場所に力を入れていると感じた為)'
            },{
                title: '当校の学校研究はどうやって行なったか',
                details: '展示会・OG からのお話など'
            },{
                title: '当校の学校訪問で見ておいた方がいいポイントはどこか',
                details: '展示会では、人を思いやる気持ちが感じられると思います。'
            },{
                title: '当校の願書を書く時に特に心がけたことは何か',
                details: 'あまり飾り過ぎず、本人の好きな事と学校が大事にしている共通点を見つけ(図書教育)それがイメージできるよ う、園での生活から聞いたエピソードを含めて書くようにしていました。'
            },{
                title: '当校の面接の雰囲気はどうだったか',
                details: '非常に和やか。小さめの応接室にて実施した為、ソファに一列で座り、面接官との距離も非常に近く、声を張ら ずに済んだのが良かった。親子課題では正解はできませんでしたが、正誤よりも家庭の雰囲気を見ているように 感じました。 面接は同校受験の数名に質問内容を聞きましたが、全て違う質問でした。'
            },{
                title: '当校の合格のため、特にしておいた方が良いことなどあるか',
                details: '2023 年組の埼玉、神奈川受験での総評として、出過ぎず出なさ過ぎずの子が良い結果が出たと大手 S 幼児教室 の代表が仰っていました。我が子も行動観察はあまり目立つタイプではなく苦戦しましたが、横浜雙葉小学校で は、そんな我が子でも良い所を見つけて合格をくださいました。お弁当考査は今年もありませんでしたが、復活 した場合に備えて、日頃から意識していました。'
            }
        ]
    },
    rikkyo_1: {
        title: '立教小学校(在校生)',
        impressions: [
            {
                title: '当校のどこに魅力を感じて受験したか',
                details: '建学の精神、一貫連携教育、キリスト教教育による人格形成'
            },{
                title: '当校の学校研究はどうやって行なったか',
                details: '学校ホームページ、プレジデントファミリー等の教育系雑誌'
            },{
                title: '当校の学校訪問で見ておいた方がいいポイントはどこか',
                details: '校⻑先生のお話、設備、子供達の様子'
            },{
                title: '当校の願書を書く時に特に心がけたことは何か',
                details: '立教の特色(他校との差別化)である、一貫連携教育、男子校、キリスト教教育それぞれの良さに絡めて、子供 をどう育てていきたいか、という方針を記載しました。'
            },{
                title: '当校の面接の雰囲気はどうだったか',
                details: '校⻑先生と教頭先生の面接のどちらかに振り分けられます。雰囲気はとても柔らかく、話しやすいです。'
            },{
                title: '当校の合格のため、特にしておいた方が良いことなどあるか',
                details: '先生とマンツーマンの試験になるので、人の話をきちんと聞けること。4 分程度椅子に静かに座っていられるこ と。歌が好きだと尚良し。'
            },{
                title: '入学して良かったと思うのはどんなところか',
                details: 'コロナ禍の入学になってしまったので、休校が多く大変でしたが、水泳の授業など、先生の補助として大学生も 来てくださるので、授業が手厚く感じます。 男子校ならではなのか、スポーツ関連のイベントが多いです。縦割りのキャンプもあり、他学年との関わりも多 い。立女との交流会(プレーデー)。'
            },{
                title: '入学してから思っていたのと違う点はあるか',
                details: '台風や大雪だと休校になる確率が高いです。(電車通学の事情) その他、先生方の研究会や、成績整理日等、休校があります。'
            },{
                title: '保護者の雰囲気などはどうか',
                details: '色々な方がいらっしゃいますが、大体は男の子のお母様なので、おおらかで感じの良い方が多い印象。'
            },{
                title: 'どんなお子様が多いと感じるか',
                details: '色々なお子様がいらっしゃいます。「好きなこと」があると、のびのび研究したり活動が出来る場があります。'
            },{
                title: '学校に出向く頻度',
                details: '各学期に一度、PTA と授業参観、保護者面談。その他、運動会、学習発表会など。 また、任意ですが、月イチで父母会、ボランティア活動等。(私は参加したことはありませんが。) 早めに年間予定表を学校からいただけるので予定は立てやすいです。'
            }
        ]
    },
    rikkyojoshi_1: {
        title: '立教女学院小学校(在校生)',
        impressions: [
            {
                title: '当校のどこに魅力を感じて受験したか',
                details: '一番には学校の温かさです。在校生の保護者より、この学校の児童の笑顔が素晴らしいこと、細やかに保護者に もご連絡くださる学校だということなどを伺っていました。 説明会では、「ご縁がなくても、学校の見る目がなかったと思ってください。」という言葉等に心をうたれました。 また、夏休み作品展示では、多様性と作品のレベルの高さに感動しました。'
            },{
                title: '当校の学校研究はどうやって行なったか',
                details: '説明会、学校見学、塾や在校生保護者からのお話'
            },{
                title: '当校の学校訪問で見ておいた方がいいポイントはどこか',
                details: '夏休みの作品展は、先生方も誇りに思われているようですので(多様性、保護者の熱心さと感じられるとのこと)、 見学された方がよろしいかと思います。'
            },{
                title: '当校の願書を書く時に特に心がけたことは何か',
                details: '学校の方針と家庭方針の一致。親が子供をきめ細やかに見られるかは重視されていると感じます。'
            },{
                title: '当校の面接の雰囲気はどうだったか',
                details: '大変温かく、それにより志望度があがりました。'
            },{
                title: '当校の合格のため、特にしておいた方が良いことなどあるか',
                details: '家庭で子供をよく観察して、成⻑のために必要なことを家族で温かく取り組まれるとよろしいかと思います。 最低限のペーパーも必要そうです。'
            },{
                title: '入学して良かったと思うのはどんなところか',
                details: '素敵なお子様が多いところ。 担任の先生が細やかに見てくださり、安心感のあるところ。 上級生がとても優しく面倒をみてくださり、下級生も憧れをもって取り組めます。'
            },{
                title: '入学してから思っていたのと違う点はあるか',
                details: '女子校の中では自由なイメージでしたが、字なども丁寧にご指導くださいます。(親のサポートは求められます)'
            },{
                title: '保護者の雰囲気などはどうか',
                details: '温かい方が多いです。想像よりも共働きの方が多く、祖父母やシッターさんにお迎えをお願いされている方も結 構いらっしゃいます。'
            },{
                title: 'どんなお子様が多いと感じるか',
                details: 'バランスの良いお子さん。 元気で、運動も好きな子が多いですが、比較的穏やかで、リーダーとしてしきるような子はあまりいないかなと 思います。'
            },{
                title: '学校に出向く頻度はどのくらいか',
                details: 'ボランティアがなければ、4 月はかなり多かった気がしますが、夏休みまでは月数回、2 学期以降は月一回もな くなりました。'
            }
        ]
    },
    rikkyojoshi_2: {
        title: '立教女学院小学校(在校生)',
        impressions: [
            {
                title: '当校のどこに魅力を感じて受験したか',
                details: '公開授業での生徒さんたちの意欲的な姿勢や先生方のそれを受け止める姿に共感し受験しました。'
            },{
                title: '当校の学校研究はどうやって行なったか',
                details: '在校生に知り合いなどコネクションが全く無かったので学校 HP をみたり(フォトブログはリアルな行事や学校 が見られます)、説明会に参加しました。'
            },{
                title: '当校の学校訪問で見ておいた方がいいポイントはどこか',
                details: '毎朝全生徒で行われる礼拝は感動します。また学校公開で展示される生徒の作品は学校がどのような生徒を望む のかが見えてくると思い是非見て頂きたいです。'
            },{
                title: '当校の願書を書く時に特に心がけたことは何か',
                details: 'キリスト教の学校ですのでその信仰を理解したうえで自分たちだけのエピソード(学校公開での様子など)を織 り込み共感できる内容にしました。'
            },{
                title: '当校の面接の雰囲気はどうだったか',
                details: '校⻑面接でしたのでとても優しく雰囲気でした。もう 1 人先生がおり始終ゆっくりとした雰囲気で行われるので しっかりと伝える事を込めないと難しいと感じました。'
            },{
                title: '当校の合格のため、特にしておいた方が良いことなどあるか',
                details: 'お箸の持ち方・使い方、エプロンの紐結びは入学すると直ぐに必要になるスキルなので必須です。'
            },{
                title: '入学して良かったと思うのはどんなところか',
                details: '個性を大切にする自由な環境なので活発で素直なお嬢様が多く、我が子はたくさんの刺激を頂き意欲的になった と感じております。'
            },{
                title: '入学してから思っていたのと違う点はあるか',
                details: '宿題量などは少なく厳しいという感じがない点。勉強よりも体験や文化に触れるなどの時間が多めです。'
            },{
                title: '保護者の雰囲気などはどうか',
                details: '明るく元気なお母様と忙しくても娘の為に行動するお父様。社交的で明るい方が多いです。お父様主催の会が学 年ごとにありイベントが年間 4 回くらい実施されます(任意参加です)'
            },{
                title: 'どんなお子様が多いと感じるか',
                details: '親同様に明るく活発なお嬢様が多いです。学校以外の習い事などでも活躍していたり目標に取り組んでいる方が います。'
            },{
                title: '学校に出向く頻度はどのくらいか',
                details: '学年にもよりますがトータルすると月 1 回くらい、プラス面談が 4 月と 7 月にあります。 授業参観は特定日が設けられておらず希望日時を連絡して参観します。'
            }
        ]
    },
    waseda_1: {
        title: '早稲田実業学校初等部(新入生)',
        impressions: [
            {
                title: '当校のどこに魅力を感じて受験したか',
                details: '自分で調べたり(自主性)、討論をしたり(他人の意見もしっかりと聞き、尊重する)など、生きた学習を大切に しているところ。中学受験のための時間を自分がやりたいことに注げること。'
            },{
                title: '当校の学校研究はどうやって行なったか',
                details: '学校説明会のみ(見学会は申込みできませんでした)。'
            },{
                title: '当校の学校訪問で見ておいた方がいいポイントはどこか',
                details: '内部の詳しい見学はできませんでしたが、校庭はとても広く、図書館にはたくさんの本がありました。'
            },{
                title: '当校の願書を書く時に特に心がけたことは何か',
                details: '自主性と粘り強さ、国際理解教育を強調しました。'
            },{
                title: '当校の面接の雰囲気はどうだったか',
                details: `
学校⻑先生のお部屋でしたが、時折り笑いもあり、和やかな雰囲気でした。質問していない先生が答えるまでの 時間をストップウォッチで測っていました。  
両親には 1 問ずつ質問されました。(父:スマホは禁止で始業式後から 1 人で通学することになるが大丈夫か、 母:最近子供を褒めた場面を教えてください)
子どもには以下の内容が質問されました。
1. 晴れの日は学校で何をしているの?
2. 雨の日はサッカーできないよね?(何をしていますか?という聞き方で はなく、自分で質問されているということに気づき、答える必要あり。)
3. トランプも色々あるよね。
4. 神経衰弱 って聞いたことあるけど、忘れちゃったなぁ。(説明力を見ている?)
5. お父さんとお母さんともやる?
6. どっち が強いの?
7. 知っている英語を教えて。(※願書で日常生活に英語を取り入れていると書いたので)
8. (母の回答 を受けて)何回くらい練習したの?どこに気をつけたらできたの?  
(※自分以外の両親への質問もしっかりと聞 いていないと答えられなかったと思います)  

早稲田で必要となる「後ろを振り返らない、無言にならない」に加えて、「質問の内容に的確に答える、自分の言 葉で説明できる、願書を共有する、当日の両親への質問もしっかりと聞いている」ことが重要であると思いまし た。
`
            },{
                title: '当校の合格のため、特にしておいた方が良いことなどあるか',
                details: 'ペーパーは過去問をみても基本が抑えられていれば対応できると思います(言い回しが難しかったり、考える問 題あり)。逆にお話の記憶も含めて、ほぼ完答が必要でしょうか。 生活習慣は過不足なく、何が出ても初見にならないような配慮が大事だと思います。'
            }
        ]
    },
    waseda_2: {
        title: '早稲田実業学校初等部(在校生)',
        impressions: [
            {
                title: '当校のどこに魅力を感じて受験したか',
                details: '「人を大事に、他人を大事に、ものを大事に」という、我が家が人として本当に大切にしてほしいと考えること が校訓として掲げられていたこと。 また、一貫校で一つの敷地に中高もあり交流する中で、憧れられる先輩が近くにいる環境。一貫校で、一生の友 達と思える友達に出会ってほしかった。'
            },{
                title: '当校の学校研究はどうやって行なったか',
                details: '塾の先生に聞いたり、実際学校の近くまで行き在校生を見たり、通っている知り合いがいれば聞いてもらいまし た。'
            },{
                title: '当校の学校訪問で見ておいた方がいいポイントはどこか',
                details: '児童がいれば児童同士の関わり合い、絵などの掲示物。表現力がみなさん高いと感じました。'
            },{
                title: '当校の願書を書く時に特に心がけたことは何か',
                details: '教育方針である三敬主義と家庭の教育方針がどのように一致しているかをアピールしました。 そして、現在どんなふうに育っているかなど。三敬主義を通じて、どんな子供になってほしいかということを書 きました。'
            },{
                title: '当校の面接の雰囲気はどうだったか',
                details: 'かなりの緊張感、緊迫した雰囲気でした。先生は 4 人で、終始無言の先生も、笑顔で対応してくれる先生もいま した。予想していた面接スタイルとは違ったため焦りました。とにかく空気が重く緊張しました。'
            },{
                title: '当校の合格のため、特にしておいた方が良いことなどあるか',
                details: 'とにかく自分のことは自分でする、お手伝いなども毎日積極的にする、大人との会話をたくさんして自分の意見 をきちんと言えるようにしておく。 ペーパーも範囲が広いので、何が出ても良いように幅広くやっておいた方がいいかと思います。 失敗しても立て直す力、気にせず切り替えて次に進むような気持ちも大事かと思います。'
            },{
                title: '入学して良かったと思うのはどんなところか',
                details: '自立している子が多いので、影響を受けてどんどん自分のことは自分でやったり出来ないことでも意欲的にする ようになった。精神的な成⻑が大きいと感じています。 友達との支援関係を作れるようになってきた。物事を自分軸で捉えられるようにもなってきたかと思います。'
            },{
                title: '入学してから思っていたのと違う点はあるか',
                details: '想像以上に元気な子が多い'
            },{
                title: '保護者の雰囲気などはどうか',
                details: '皆さんとても丁寧で謙虚で素敵な方ばかりです。'
            },{
                title: 'どんなお子様が多いと感じるか',
                details: '男女とも、とにかく元気。おとなしい子もいますが、でも皆自分の意見はしっかり持っていて言えるという印象です。あとは気の強い子が多い印象です。大人とお話するのが好きなんだなという印象もかなりあります。'
            },{
                title: '学校に出向く頻度はどのくらいか',
                details: '3 ヶ月に一度程度(保護者会と参観日)'
            }
        ]
    },
    tsukuba_1: {
        title: '筑波大学附属小学校(在校生)',
        impressions: [
            {
                title: '当校のどこに魅力を感じて受験したか',
                details: '友人のお子さまが在校生で、しっかりされていたから。文武両道。'
            },{
                title: '当校の学校研究はどうやって行なったか',
                details: '本'
            },{
                title: '当校の学校訪問で見ておいた方がいいポイントはどこか',
                details: '受験時のみ訪問可能。子供が考査中の、親向けの学校紹介映像。(涙が出そうになるほど感動しました)'
            },{
                title: '当校の願書を書く時に特に心がけたことは何か',
                details: 'web での簡単な願書なので、前向きに記載するレベルです。'
            },{
                title: '当校の面接の雰囲気はどうだったか',
                details: '親の面接なし。子供は集団の中で見られる程度かな?!'
            },{
                title: '当校の合格のため、特にしておいた方が良いことなどあるか',
                details: '読み聞かせ'
            },{
                title: '入学して良かったと思うのはどんなところか',
                details: '先生も生徒も(親も)みんな一生懸命で、環境がよい。行事が多く、子供にとって色々な刺激がある。'
            },{
                title: '入学してから思っていたのと違う点はあるか',
                details: '予想していた以上に、先生から親のフォローを必要とされている。'
            },{
                title: '保護者の雰囲気などはどうか',
                details: '裏表がない、よい方が多いです。裕福なご家庭が多い印象です。'
            },{
                title: 'どんなお子様が多いと感じるか',
                details: '何かしらの得意なことを持ったお子さまが多いです。(詩の暗唱が早い、読者好き、足が速い、算数好き、ピア ノ、バイオリンなど)'
            },{
                title: '学校に出向く頻度はどのくらいか',
                details: '全員が 3 年に一度クラス役員にあたり、役員時は毎月行きます。月に 2.3 回行くことも。役員でない年は、2.3 ヶ 月に 1 回程度。'
            }
        ]
    },
    gakugeioizumi_1: {
        title: '東京学芸大学附属大泉小学校(在校生)',
        impressions: [
            {
                title: '当校のどこに魅力を感じて受験したか',
                details: '国際色豊かな教育、自由な校風、附属中学への進学枠'
            },{
                title: '当校の学校研究はどうやって行なったか',
                details: 'コロナ禍だったので学校訪問は出来ず、オンライン説明会、知人からの情報、ウェブからの情報'
            },{
                title: '当校の学校訪問で見ておいた方がいいポイントはどこか',
                details: '先生や子供達の雰囲気、施設、教育カリキュラム'
            },{
                title: '当校の願書を書く時に特に心がけたことは何か',
                details: '基本情報のみだったと思います。'
            },{
                title: '当校の面接の雰囲気はどうだったか',
                details: '子供のみで、親は廊下で待っています。とても短時間で終わります。'
            },{
                title: '当校の合格のため、特にしておいた方が良いことなどあるか',
                details: '問題が独特なので、過去問対策はやっておいた方が良いと思います。'
            },{
                title: '入学して良かったと思うのはどんなところか',
                details: '子供達それぞれのペースに合った教育をしてくださるので、無理なくのびのびとしていられます。良い意味で能 力も性格も偏っておらず、様々なタイプの子供たちと接することが出来ます。'
            },{
                title: '入学してから思っていたのと違う点はあるか',
                details: '良い意味で子供達の自主性に任せる場面がとても多く、全員一律で一定のレベルにまで引き上げるような教育で はありません。'
            },{
                title: '保護者の雰囲気などはどうか',
                details: '全体的に子供達を温かく見守る雰囲気があり、両親共働きの家庭も比較的多いので、それぞれの家庭に合った形 でお付き合いが可能。'
            },{
                title: 'どんなお子様が多いと感じるか',
                details: '活発で素直、子供らしい子が多いように感じます。'
            },{
                title: '学校に出向く頻度はどのくらいか',
                details: '我が家は共働きなので、子供の行事と保護者会、引き取り訓練等、最低限のレベルでしか参加出来ていないので、 年に 5、6 回だと思います。'
            }
        ]
    }
    // id: {
    //     title: '',
    //     impressions: [
    //         {
    //             title: '',
    //             details: ''
    //         },{
    //             title: '',
    //             details: ''
    //         },{
    //             title: '',
    //             details: ''
    //         },{
    //             title: '',
    //             details: ''
    //         },{
    //             title: '',
    //             details: ''
    //         },{
    //             title: '',
    //             details: ''
    //         },{
    //             title: '',
    //             details: ''
    //         },{
    //             title: '',
    //             details: ''
    //         },{
    //             title: '',
    //             details: ''
    //         },{
    //             title: '',
    //             details: ''
    //         },{
    //             title: '',
    //             details: ''
    //         }
    //     ]
    // }
}