export const regularCourses = [
    {
        title: '[KOKIA membership lab ≫](https://ameblo.jp/sakura-sakulabo/entry-12830374330.html)',
        short: `
当教室最優位の会員制サービスです。  
手厚いサポートで、志望校合格を全面的にバックアップいたします。
`
    },
    {
      title: '[《オンライン》おはなしの練習講座 ≫](https://ameblo.jp/sakura-sakulabo/entry-12821430132.html)',
      short: `
姿勢や話し方のアドバイスを交えながら、お子様と桜井がマンツーマンで対話能力向上を目指す講座です。
`
   },
       {
      title: '[個別相談 ≫](https://ameblo.jp/sakura-sakulabo/entry-12838190774.html)',
      short: `
小学校受験に関することなら何でも構いません。あなただけに向けたアドバイスをさせていただきます。
「ちょっと聞きたい」から「どうしても聞きたい」まで全力でアドバイスいたします！
`
   }
//     {
//       title: '',
//       short: `
// `,
//       details: `
// `
//    }
    ]

    export const limitedCourses = [
      {
         title: '[ペーパー攻略講座 ≫](https://ameblo.jp/sakura-sakulabo/entry-12832453093.html)',
         short: `
自宅学習だけで、トップを狙おう！  
知っておくべき"解法のコツ"と"ペーパー学習の進め方"をお伝えする保護者向けの講座です。
`
      },
      {
         title: '[願書添削 ≫](https://ameblo.jp/sakura-sakulabo/entry-12834410577.html)',
         short: `
2年前から予約が入るほどの不動の人気No.1サービスです！  
唯一無二のオリジナル願書を一緒に作成し、自信を持って当日の考査を迎えましょう。
`
      },
      {
         title: '[面接対策講座 ≫](https://ameblo.jp/sakura-sakulabo/entry-12857871013.html)',
         short: `
志望校別に納得がいくまで何度でも練習することが可能です。お子様の志望校合格に向けて、しっかり親も準備をして臨みましょう。
`
      },
      {
         title: '[模試の講評講座 ≫](https://ameblo.jp/sakura-sakulabo/entry-12858521706.html)',
         short: `
模試を受けっぱなしにしていませんか。模試を活用し、次の飛躍へ繋げるための具体的なアドバイスをさせていただきます。
`
      },
      {
         title: '各種講習会',
         short: `
皆様のニーズにピンポイントでお応えできるような講座を随時企画・募集しております。  
最新情報は、[**ブログ**](https://www.ameba.jp/profile/general/sakura-sakulabo/)でご確認ください。
`
      }
//    {
//       title: '',
//       short: `
// `,
//       details: `
// `
//    }
    ]