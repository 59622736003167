import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import News from "./pages/News";
import Welcome from './pages/Welcome';
import {
    createBrowserRouter,
    createRoutesFromElements, Navigate,
    Route,
    RouterProvider,
} from "react-router-dom";
import Intro from "./pages/Intro";
import Achievements from "./pages/Achievement";
import Shop from "./pages/Shop";
import Layout from "./Layout";
import { LoginProvider, ProtectedRoute } from './login/login-provider';
import { LoginLanding } from './login/login-page';
import SchoolInfo from './schools/schools-page';
import { UserAdmin } from './admin/user/user-page'


// work around for RouterProvider not accepting children
const LoginProviderLayout = () => (
    <LoginProvider>
        <Layout />
    </LoginProvider>
);

const router = createBrowserRouter(
    createRoutesFromElements([
        <Route path="/" element={<LoginProviderLayout />}>
            <Route path="/" element={<Welcome />} />,
            <Route path="/news" element={<News />} />,
            <Route path="/intro" element={<Intro />} />,
            <Route path="/shop" element={<Shop />} />,
            <Route path="/achievement" element={<Achievements />} />,
            <Route path="/login" element={<LoginLanding />} />,
            <Route path="/schools/:story" element={
                <ProtectedRoute grant='member'>
                    <SchoolInfo />
                </ProtectedRoute>
            } />,
            <Route path="/schools" element={
                <ProtectedRoute grant='member'>
                    <SchoolInfo />
                </ProtectedRoute>
            } />,
            <Route path="/users" element={
                <ProtectedRoute grant='admin'>
                    <UserAdmin />
                </ProtectedRoute>
            } />,
            
        </Route>,
        <Route path="*" element={<Navigate to="/" />} />
    ])
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
