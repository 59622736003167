import './news.css';
import {allNews} from '../api/news'
import { KokiaNewsItem } from '../components/kokia-news';
import { KokiaPageTitle } from '../components/kokia-section';

function News() {
    return (
        <>
            <KokiaPageTitle label='お知らせ' />

            <div className='news-container'>
                {allNews.map((item, index) => 
                    <KokiaNewsItem item={item} key={index}/>
                )}
            </div>
        </>
    );
}

export default News;