import './kokia-section.css';
import { Link } from "react-router-dom";

export const KokiaPageTitle = (props) =>
    <div className={`page-title-content ${'short' in props ? 'short' : ''}`}>
        <div className="kokia-watermark"></div>
        <div className="kokia-watermark"></div>
        <div className="kokia-watermark"></div>
        <div className="page-title-text">{props.label}</div>
        <div className="kokia-watermark"></div>
        <div className="kokia-watermark"></div>
        <div className="kokia-watermark"></div>
    </div>

export const PageSectionTitle = ({ title }) => (
    <div  className='kokia-page-section-title'>
        <div className='illust'></div>
        <div className='label'>{title}</div>
    </div>
)

export const SectionTitle = ({ title, toLabel, to }) => (
    <div className="kokia-section-title">
        <div className="kokia-watermark"></div>
        <div>{title}</div>
        <div>{to ? <Link to={to}>{toLabel}</Link> : null}</div>
    </div>
)

export const SectionContent = ({children, contentClass}) => (
    <div className={`kokia-section-content ${contentClass || ''}`}>
        {children}
    </div>
)