
export const allNews = [
    {
        publishTs: "2024/09/21",
        content: "[KOKIA membership lab、おはなしの練習の次回募集は10/25です。](https://ameblo.jp/sakura-sakulabo/entry-12865305047.html)"
    },
    {
        publishTs: "2024/05/24",
        content: "本年の夏期休業は、8/1〜8/7です"
    },
    {
        publishTs: "2024/02/10",
        content: "願書添削の受付は終了しました"
    },
    {
        publishTs: "2024/01/04",
        content: "[願書添削についてのお知らせ](https://ameblo.jp/sakura-sakulabo/entry-12834410577.html)"
    },
    {
        publishTs: "2023/12/16",
        content: "ペーパー攻略講座は完売しました"
    },
    {
        publishTs: "2023/12/05",
        content: "ホームページを開設しました"
    }
]
export const loadTop3NewsItems = () => {
    return allNews.slice(0, 3);
}
